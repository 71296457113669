import React from 'react'
import PropTypes from 'prop-types'
// import { Waypoint } from 'react-waypoint';
// import anime from 'animejs/lib/anime.es.js';
import "./our-process.scss"

import Card from "../../shared/card/card";

function OurProcess(props) {
    return (
        <section className="our-process-container">
            <div className="our-process-title-container">
                <h2 className="our-process-title">{props.title}</h2>
            </div>
            <div className="our-process-desc-container">
                <p className="our-process-desc">{props.description}</p>
            </div>
            <div className="our-process-elements-container">
                {
                    props.elements.map((item) => {

                        return (
                            <div className="our-process-elements-item" key={item.id}>

                                <Card
                                    title={item.title}
                                    description={item.desc}
                                    iconSrc={item.iconSrc}
                                    type="normal"
                                />

                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

OurProcess.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    elements: PropTypes.array
}

OurProcess.defaultProps = {
    title: ``,
    description: ``,
    elements: []
}

export default OurProcess

