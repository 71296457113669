import React from 'react'
import PropTypes from 'prop-types'
import Button from "../../shared/button/button"
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';
import "./main-banner.scss"

function MainBanner(props) {

    function scrollIn() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: '.main-banner-desc',
                // translateX: ['100%', '0%'],
                opacity: [0, 1],
                easing: 'linear',
                duration: 500
            })
            .add({
                targets: '.main-banner-btn',
                opacity: [0, 1],
                direction: 'reverse',
                duration: 250,
                easing: 'linear'
            });
    }

    function scrollOut() {
    }

    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <div className="main-banner-container">
                <h1 className="main-banner-desc">{props.description}</h1>
                <Button classNameProps="main-banner-btn" type="A" title={props.contactUsLabel} link={props.contactUsAction} />
            </div>
        </Waypoint>
    )
}

MainBanner.propTypes = {
    description: PropTypes.string,
    contactUsLabel: PropTypes.string,
    contactUsAction: PropTypes.string
}

MainBanner.defaultProps = {
    description: ``,
    contactUsLabel: ``,
    contactUsAction: ``
}

export default MainBanner