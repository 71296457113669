import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';
import "./about-briefly.scss"

import bgImgDesktop from '../../../images/curves-desktop.svg';
import bgImgTablet from '../../../images/curves-tablet.svg';
import bgImgMobile from '../../../images/curves-mobile.svg';

function AboutBriefly(props) {

    function scrollIn() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: '.about-briefly-title',
                opacity: [0, 1],
                delay: 500,
                duration: 250,
                direction: 'reverse',
                easing: 'linear'
            })
            .add({
                targets: '.about-briefly-desc',
                opacity: [0, 1],
                direction: 'reverse',
                duration: 250,
                easing: 'linear'
            });
    }

    function scrollOut() {
    }

    let bgImage;
    if (typeof window !== `undefined`) { //to fix 'window undefined' on building process 
        if (window.matchMedia('(min-width: 320px)').matches) {
            bgImage = <img src={bgImgMobile} alt="background"/>;
        }
        if (window.matchMedia('(min-width: 768px)').matches) {
            bgImage = <img src={bgImgTablet} alt="background"/>;
        }
        if (window.matchMedia('(min-width: 1440px)').matches) {
            bgImage = <img src={bgImgDesktop} alt="background"/>;
        }
    }

    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <section className="about-briefly-container">
                <picture className="about-briefly-bg-img">
                    {bgImage}
                </picture>
                <h3 className="about-briefly-title">{props.title}</h3>
                <p className="about-briefly-desc">{props.description}</p>
            </section>
        </Waypoint>
    )

}

AboutBriefly.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
}

AboutBriefly.defaultProps = {
    title: ``,
    description: ``
}

export default AboutBriefly

