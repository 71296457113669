import React from "react"
import { Waypoint } from 'react-waypoint'
import anime from 'animejs/lib/anime.es.js'
import "./services-briefly.scss"

import Button from "../../shared/button/button"

export default class ServicesBriefly extends React.Component {
    constructor(props) {
        super(props)
        this.props = props
        this.title = props.title
        this.elements = props.elements
        this.serviceTitleRefs = new Map()
        this.serviceDescRefs = new Map()

        this.scrollIn = this.scrollIn.bind(this)
        this.scrollOut = this.scrollOut.bind(this)
    }

    static defaultProps = {
        title: ``,
        elements: [],
    }

    scrollIn(key) {
        const titleRef = this.serviceTitleRefs.get(key);
        const descRef = this.serviceDescRefs.get(key);
        const animElements = anime.timeline();

        animElements
            .add({
                targets: titleRef,
                opacity: [0, 1],
                delay: 150,
                duration: 250,
                direction: 'reverse',
                easing: 'linear'
            })
            .add({
                targets: descRef,
                opacity: [0, 1],
                delay: 150,
                duration: 250,
                direction: 'reverse',
                easing: 'linear'
            });

    }

    scrollOut() {
    }

    fadeInElements() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: ['.services-briefly-elements-item-title', '.services-briefly-elements-item-desc'],
                opacity: [0, 1],
                delay: function (el, i, l) { return i * 100; },
                easing: 'easeInSine'
            });
    }

    fadeOutElements() {
    }

    render() {
        return (
            <section className="services-briefly-container">
                <div className="services-briefly-title-container">
                    <h2 className="services-briefly-title"> {this.title} </h2>
                </div>
                <Waypoint
                    onEnter={() => {
                        if (window.matchMedia('(min-width: 769px)').matches) {
                            this.fadeInElements();
                        }
                    }}
                    onLeave={this.fadeOutElements}
                >
                    <div className="services-briefly-elements-container">
                        {this.elements.map(item => {
                            return (
                                <Waypoint
                                    onEnter={() => {
                                        if (window.matchMedia('(max-width: 768px)').matches) {
                                            this.scrollIn(item.id);
                                        }
                                    }}
                                    onLeave={this.scrollOut} 
                                    key={item.id}
                                >
                                    <div
                                        className="services-briefly-elements-item"
                                    >
                                        <div
                                            className="services-briefly-elements-item-thumb"
                                            style={{
                                                backgroundImage: `url(${item.imgSrc})`,
                                            }}
                                        >
                                            <h3
                                                className="services-briefly-elements-item-title"
                                                ref={ref => { this.serviceTitleRefs.set(item.id, ref) }}
                                            >
                                                {item.title}
                                            </h3>
                                        </div>
                                        <div className="services-briefly-elements-item-desc-box">
                                            <p
                                                className="services-briefly-elements-item-desc"
                                                ref={ref => { this.serviceDescRefs.set(item.id, ref) }}
                                            >
                                                {item.description}
                                            </p>
                                            <Button
                                                classNameProps="services-briefly-elements-item-link"
                                                type="A"
                                                title={item.btnLabel}
                                                link={item.link}
                                            />
                                        </div>
                                    </div>
                                </Waypoint> 
                            )
                        })}
                    </div>
                </Waypoint>
            </section>
        )
    }
}
