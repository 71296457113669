import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import MainBanner from "../components/shared/main-banner/main-banner"
import ServicesBriefly from "../components/home/services-briefly/services-briefly"
import WhyUs from "../components/home/why-us/why-us"
import SEO from "../components/shared/seo"
import ContactBriefly from "../components/shared/contact-briefly/contact-briefly"
import AboutBriefly from "../components/home/about-briefly/about-briefly"
import OurProcess from "../components/home/our-process/our-process"

import websitesImg from "../images/banners-img/websites-banner-img-tiny.jpg";
import webAppsImg from "../images/banners-img/web-apps-banner-img-tiny.jpg";
import mobileAppsImg from "../images/banners-img/mobile-apps-banner-img-tiny.jpg";
import consultingImg from "../images/banners-img/consulting-banner-img-tiny.jpg";

import designIcon from "../images/icons/design.svg";
import strategyIcon from "../images/icons/strategy.svg";
import contentIcon from "../images/icons/content.svg";
import agileIcon from "../images/icons/agile.svg";
import valueIcon from "../images/icons/value.svg";

const processElements = [
	{ id: "design", title: "Diseño centrado en el usuario", desc: "Cada solución debe estar basada en los problemas y necesidades que tengan los usuarios obteniendo así satisfacción y aceptación de los mismos.", iconSrc: designIcon },
	{ id: "strategy", title: "Estrategia", desc: "Se busca alinear la estrategia y objetivos de negocio con los productos digitales que se pretenden desarrollar.", iconSrc: strategyIcon },
	{ id: "content", title: "Contenido", desc: "El contenido de calidad es esencial para atraer clientes y generar una audiencia que le permita promocionar sus productos y servicios de manera eficaz.", iconSrc: contentIcon },
	{ id: "development", title: "Desarrollo Ágil", desc: "El enfoque ágil por su flexibilidad y retroalimentación constante permite obtener productos y servicios diferenciados e integrales.", iconSrc: agileIcon },
	{ id: "value", title: "Valor", desc: "Crear valor es el objetivo final en cada una de nuestras soluciones, entendemos su importancia y conocemos los beneficios que tiene para su negocio.", iconSrc: valueIcon }
];


const servicesElements = [
	{ id: "websites", imgSrc: websitesImg, altText: "Imagen alusiva a sitios web.", title: "Páginas Web", description: "Desarrolle presencia online y conecte con sus clientes de manera efectiva.", link: "paginas-web", btnLabel: "Desarrollo de páginas web" },
	{ id: "webapps", imgSrc: webAppsImg, altText: "Imagen alusiva a aplicaciones web.", title: "Aplicaciones Web", description: "Desarrolle aplicaciones web que brinden el valor que su negocio u organización necesita.", link: "aplicaciones-web", btnLabel: "Desarrollo de aplicaciones web" },
	{ id: "mobileapps", imgSrc: mobileAppsImg, altText: "Imagen alusiva a aplicaciones móviles.", title: "Aplicaciones Móviles", description: "Desarrolle productos y servicios más personalizados a través de tecnologías móviles.", link: "aplicaciones-moviles", btnLabel: "Desarrollo de aplicaciones móviles" },
	{ id: "consulting", imgSrc: consultingImg, altText: "Imagen alusiva a aplicaciones móviles.", title: "Consultoría", description: "Transforme sus estrategias de negocio en productos y servicios tecnológicamente viables.", link: "consultoria-informatica", btnLabel: "Consultoría informática" }
]
const IndexPage = () => (
	<Layout>
		<SEO title="Servicios de Desarrollo Web" description="Hacer que su negocio utilice la tecnología para ser más competitivo y productivo en el mundo digital, es nuestra prioridad." lang="es" meta={[
			{
				property: `og:type`,
				content: `website`,
			},
			{
			    property: `og:image`,
			    content: 'https://cms.spheredevs.com/wp-content/uploads/2020/09/Landing-Page-Preview-Image_COMPRESSED.jpg'
			},
			{
				property: `og:locale`,
				content: 'es_CR',
			},
			{
				name: `twitter:card`,
				content: `summary`,
			},
			{
				name: `twitter:title`,
				content: "Servicios de Desarrollo Web",
			},
			{
				name: `twitter:description`,
				content: "Hacer que su negocio utilice la tecnología para ser más competitivo y productivo en el mundo digital, es nuestra prioridad.",
			},
			{
				name: `twitter:creator`,
				content: `@SphereDevs`
			},
			{
				name: `twitter:site`,
				content: `@SphereDevs`,
			}
		]}/>
		<MainBanner description="Permítanos sacarle el máximo potencial a sus ideas." contactUsAction="/contacto" contactUsLabel="Iniciar proyecto"> </MainBanner>
		<WhyUs topDescription="Hacer que su negocio utilice la tecnología para ser más competitivo y productivo en el mundo digital, es nuestra prioridad."
			bottomDescription="Nos enfocamos en lo que es importante, crear valor para sus productos y servicios.">
		</WhyUs>
		<ServicesBriefly title="Lo que ofrecemos" elements={servicesElements}> </ServicesBriefly>
		<OurProcess title="Nuestro proceso" description="Se basa en el desarrollo de cinco aspectos claves para el éxito de todo proyecto" elements={processElements} />
		<AboutBriefly title="Creemos fielmente en el trabajo colaborativo." description="Vivimos en un mundo sumamente competitivo que demanda productos y servicios de excelencia. Por ende, es esencial utilizar la colaboración para alcanzar mejores resultados a la hora de desarrollar productos y servicios enfocados en brindar valor a nuestros clientes y usuarios." />
		<ContactBriefly description="¿Tienes algo en mente? No esperes más!" contactUsAction="/contacto" contactUsLabel="Iniciar proyecto" />
		<Helmet>
			<script type="application/ld+json">
				{`
					{
						"@context": "https://schema.org",
						"@type": "Organization",
						"url": "https://www.spheredevs.com",
						"name": "SphereDevs",
						"contactPoint": {
							"@type": "ContactPoint",
							"telephone": "+506 8360 9763",
							"email": "info@spheredevs.com",
							"contactType" : "customer service"
						},
						"address": {
							"@type": "PostalAddress",
							"addressLocality": "San Jose, Costa Rica"
						},
						"sameAs" : [
							"https://www.facebook.com/spheredevs",
							"https://twitter.com/SphereDevs",
							"https://www.instagram.com/spheredevs"
						]
					}
      			`}
			</script>
		</Helmet>
	</Layout>
)

export default IndexPage
