import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';
import "./card.scss"

function Card(props) {

    let cardRef = null;
    let cardInfoRef = null;

    function scrollIn() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            const animElements = anime.timeline();
            animElements
                .add({
                    targets: cardInfoRef,
                    opacity: [0, 1],
                    delay: 1500,
                    duration: 250,
                    direction: 'reverse',
                    easing: 'linear',
                    begin: function (anim) {
                        cardRef.classList.add('card-focused');
                    }
                });
        }
    }

    function scrollOut() {
        cardRef.classList.remove('card-focused');
    }

    function fadeIn() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: cardInfoRef,
                opacity: [0, 1],
                delay: 0,
                duration: 250,
                direction: 'reverse',
                easing: 'linear',
                begin: function (anim) {
                    cardRef.classList.add('card-focused');
                }
            })
    }

    function fadeOut() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: cardInfoRef,
                opacity: [1, 0],
                delay: 0,
                duration: 250,
                easing: 'linear',
                begin: function (anim) {
                    cardRef.classList.remove('card-focused');
                }
            })
    }

    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <div className={ props.type === 'light' ? "card light" : "card normal"} ref={(ref) => { cardRef = ref; }}>
                <div className={ props.type === 'light' ? "card-content light-content" : "card-content normal-content"} >

                    <div className="card-thumbnail">
                        <picture className="card-thumbnail-image">
                            <img src={props.iconSrc} alt="card icon"/>
                        </picture>
                        <div className="card-thumbnail-title-container">
                            <h2 className={ props.type === 'light' ? "card-thumbnail-title light-thumbnail-title" : "card-thumbnail-title normal-thumbnail-title"}>{props.title}</h2>
                        </div>
                    </div>

                    <div className="card-info" ref={(ref) => { cardInfoRef = ref; }} onMouseEnter={fadeIn} onMouseLeave={fadeOut}>
                        <div className="card-title-container">
                            <h2 className={ props.type === 'light' ? "card-title light-title" : "card-title normal-title"}>{props.title}</h2>
                        </div>
                        <div className="card-desc-container">
                            <p className={ props.type === 'light' ? "card-desc light-desc" : "card-desc normal-title"}>{props.description}</p>
                        </div>
                    </div>

                </div>
            </div>
        </Waypoint>
    )
}

Card.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    iconSrc: PropTypes.string,
    type: PropTypes.string
}

Card.defaultProps = {
    title: ``,
    description: ``,
    iconSrc: ``,
    type: `normal`
}

export default Card

