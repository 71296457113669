import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';
import "./why-us.scss"

function WhyUs(props) {

    function scrollIn() {
        anime({
            targets: ['.why-us-top-desc', '.why-us-bottom-desc'],
            opacity: [0, 1],
            delay: function (el, i, l) { return i * 1000; },
            easing: 'easeInSine'
        });
    }

    function scrollOut() {
    }

    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <section className="why-us">
                <div className="why-us-top-container">
                    <h2 className="why-us-top-desc">{props.topDescription}</h2>
                </div>
                <div className="why-us-bottom-container">
                    <h2 className="why-us-bottom-desc">{props.bottomDescription}</h2>
                </div>
            </section>
        </Waypoint>
    )
}


WhyUs.propTypes = {
    topDescription: PropTypes.string,
    bottomDescription: PropTypes.string
}

WhyUs.defaultProps = {
    topDescription: ``,
    bottomDescription: ``
}


export default WhyUs